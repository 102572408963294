import React, {Fragment, useEffect, useState} from 'react';

import s from './ReportTimePage.module.scss'
import {ITableDataHeader} from "../../../components/UI/Table/Table";
import Table from "../../../components/UI/Table";
import TableRow from "../../../components/UI/Table/components/TableRow";
import TableColumn from "../../../components/UI/Table/components/TableColumn/TableColumn";
import {ENDPOINTS} from "../../../api/endpoints";
import {DataXPartnersStats} from "../../../api/interface/Unicom";
import Loader from "../../../components/UI/Loader";
import TablePagination from "../../../components/UI/Table/components/TablePagination";
import {useParams} from "react-router-dom";
import {DateTime} from "luxon";


export default function ReportTimePage() {
    const tableGrid = ['200px', '200px', '200px', '200px', '200px', '200px', '200px'];
    const headerData: ITableDataHeader[] = [
        {title: 'Время'},
        {title: 'Трафик'},
        {title: 'Показы'},
        {title: 'Конверсии'},
        {title: 'CR'},
        {title: 'EPC'},
        {title: 'Финансы'},
    ];

    const DATA_PAGE_LIMIT: number = 20
    const [dateFrom, setDateFrom] = useState<string>(DateTime.now().toISODate())
    const [dateTo, setDateTo] = useState<string>(DateTime.now().toISODate())
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [rawData, setRawData] = React.useState<DataXPartnersStats>({
        data: [],
        pagination: {}
    });
    const {id, leadId} = useParams()

    function getData(page: number, date_from: string, date_to: string) {
        setRawData({data: [], pagination: {}})
        setIsLoading(true)

        ENDPOINTS.REPORTS.STATS1.CUSTOM.GET({
            id: !leadId ? id : undefined,
            lead_id: leadId,
            page: page,
            limit: DATA_PAGE_LIMIT,
            date_to: date_to,
            date_from: date_from,
            slice: ['year', 'month', 'day'],
        }).then((res) => {
            setRawData(res);
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getData(currentPage, dateFrom, dateTo)
    }, [currentPage, dateFrom, dateTo]);


    return (
        <div className={s.container}>
            <div className={s.container__products}>

                <div className={s.container__filter}>
                    <h3>Фильтровать: </h3>
                    <div>
                        <label htmlFor="date_from">
                            <span>От</span>
                            <input id="date_from" type="date" defaultValue={DateTime.now().toISODate()} onChange={(e) => setDateFrom(e.target.value)}/>
                        </label>

                        <label htmlFor="date_to">
                            <span>До</span>
                            <input id="date_to" type="date" defaultValue={DateTime.now().toISODate()} onChange={(e) => setDateTo(e.target.value)}/>
                        </label>
                    </div>
                </div>

                <Table dataHeaders={headerData} gridWidthSize={tableGrid}>
                    {headerData && <div className={s.underlined}/>}
                    {rawData?.data.map((item, index) => (
                        <Fragment key={index}>
                            <TableRow gridWidthSize={tableGrid}>
                                <TableColumn>
                                    <div>{DateTime.fromFormat(`${item.slice.day}.${item.slice.month}.${item.slice.year}`, 'd.L.yyyy').toFormat('D')}</div>
                                </TableColumn>

                                <TableColumn>
                                    <div>
                                        <p>Хосты: {item?.traffic?.uniq || '0'}</p>
                                        <p>Клики: {item?.traffic?.raw || '0'}</p>
                                    </div>
                                </TableColumn>

                                <TableColumn>
                                    <div>{item.views}</div>
                                </TableColumn>

                                <TableColumn>
                                    <div>
                                        <p>Все: {item?.cr?.total || '0'}</p>
                                        <p>Принято: {item?.cr?.confirmed || '0'}</p>
                                        <p>В обработке: {item?.cr?.pending || '0'}</p>
                                        <p>Холд: {item?.cr?.hold || '0'}</p>
                                        <p>Отклонено: {item?.cr?.declined || '0'}</p>
                                    </div>
                                </TableColumn>

                                <TableColumn>
                                    <div>{item?.ctr || '0'}</div>
                                </TableColumn>

                                <TableColumn>
                                    <div>{item?.epc || '0'}</div>
                                </TableColumn>

                                <TableColumn>
                                    <div>
                                        <p>Принято: {item?.actions?.confirmed?.count || '0'}</p>
                                        <p>В обработке: {item?.actions?.pending?.count || '0'}</p>
                                        <p>Холд: {item?.actions?.hold?.count || '0'}</p>
                                        <p>Отклонено: {item?.actions?.declined?.count || '0'}</p>
                                        <p>Итого: {item?.actions?.costs || '0'}</p>
                                    </div>
                                </TableColumn>
                            </TableRow>
                        </Fragment>
                    ))}
                </Table>

                {isLoading && <Loader/>}

                <TablePagination currentPage={currentPage}
                                 totalPages={Math.round(Number((rawData?.pagination?.count || 0) / DATA_PAGE_LIMIT))}
                                 onPageChange={(page) => {
                                     setCurrentPage(page)
                                 }}/>
            </div>
        </div>
    )
}
