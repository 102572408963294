import React, {useEffect, useMemo} from 'react';

import s from './AsideLayout.module.scss'
import {Link, useLocation, useParams} from "react-router-dom";
import classNames from "classnames";
import {useAppSelector} from "../../../store";

export default function AsideLayout({user, lead}: {user?: any, lead?: any}) {
    const location = useLocation()
    const {id, leadId} = useParams()
    const {leadgid_token} = useAppSelector((state) => state.profile)

    const sectionsData = useMemo(() => {
        return [
            {
                text: 'По Времени',
                url: leadId ? `/reports/stats1/time/lead/${leadId}` : id ? `/reports/stats1/time/${id}` : `/reports/stats1/time`
            },
            {
                text: 'По Офферам',
                url: leadId ? `/reports/stats1/offers/lead/${leadId}` : id ? `/reports/stats1/offers/${id}` : `/reports/stats1/offers`
            }, {
                text: 'По Конверсии',
                url: leadId ? `/reports/stats1/conversion/lead/${leadId}` : id ? `/reports/stats1/conversion/${id}` : `/reports/stats1/conversion`
            }
        ]
    }, [id, user, lead])

    const sectionsLeadGidData = useMemo(() => {
        return [
            {
                text: 'По Времени',
                url: leadId ? `/reports/stats2/time/lead/${leadId}` : id ? `/reports/stats2/time/${id}` : `/reports/stats2/time`
            },
            {
                text: 'По Офферам',
                url: leadId ? `/reports/stats2/offers/lead/${leadId}` : id ? `/reports/stats2/offers/${id}` : `/reports/stats2/offers`
            }, {
                text: 'По Конверсии',
                url: leadId ? `/reports/stats2/conversion/lead/${leadId}` : id ? `/reports/stats2/conversion/${id}` : `/reports/stats2/conversion`
            }
        ]
    }, [id, user, lead])

    return (
        <aside className={s.aside}>
            <div className={s.aside__content}>
                <p className={s.aside__title}>Статистика 1</p>
                {sectionsData.map((item) => (
                    <Link to={item.url} className={classNames({
                        [s.aside__content__text]: true,
                        [s.aside__content__text__active]: location.pathname === item.url
                    })}>{item.text}</Link>
                ))}
            </div>

            {!lead && (user?.leadgid_token || leadgid_token) ? <>
                <hr/>
                <div className={s.aside__content}>
                    <p className={s.aside__title}>Статистика 2</p>
                    {sectionsLeadGidData.map((item) => (
                        <Link to={item.url} className={classNames({
                            [s.aside__content__text]: true,
                            [s.aside__content__text__active]: location.pathname === item.url
                        })}>{item.text}</Link>
                    ))}
                </div>
            </> : ""}

        </aside>
    )
}
