import {createSlice} from '@reduxjs/toolkit'

interface ProfileState {
    id?: number,
    email?: string
    rights?: "ADMIN" | "USER"
    leadgid_token?: string
    balance?: number
}

const initialState: ProfileState = {
    id: undefined,
    email: undefined,
    rights: undefined,
    leadgid_token: undefined,
    balance: 0,
}

export const ProfileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile: (state, data: { payload: ProfileState }) => {
            if (data.payload.id) state.id = data.payload.id
            if (data.payload.email) state.email = data.payload.email
            if (data.payload.leadgid_token) state.leadgid_token = data.payload.leadgid_token
            if (data.payload.rights) state.rights = data.payload.rights
            if (data.payload.balance) state.balance = data.payload.balance
        },
    },
})

export const {setProfile} = ProfileSlice.actions

export default ProfileSlice.reducer
