import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';

import s from './HeaderLayout.module.scss';
import NavLink from "./components/NavLink/NavLink";
import UserProfile from "./components/UserProfile";
import {useAppSelector} from "../../../store";
import {footerData} from "../../../constants/FooterItems";

export default function HeaderLayout() {
    const {rights} = useAppSelector((state) => state.profile)

    return (
        <header className={s.header}>
            <div className={s.header__content}>
                <div className={s.header__content__leftPart}>
                    <Link to={"/"} className={s.header__content__leftPart__logo}>
                        <img src={'/logo.png'} alt="logo"/>
                    </Link>
                </div>

                <div className={s.header__content__rightPart}>
                    <div className={s.header__content__rightPart__links}>
                        {footerData.map((item, index) => {
                            return !item.menu && <>
                                {(item.is_admin && rights === "ADMIN") &&
                                    <NavLink key={index} text={item.title} url={item?.route!}/>}
                                {(!item.is_admin) && <NavLink key={index} text={item.title} url={item?.route!}/>}
                            </>
                        })}
                    </div>
                    <UserProfile/>
                </div>
            </div>
        </header>
    );
}
