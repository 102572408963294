import {BrowserRouter, Route, Routes} from "react-router-dom";
import UsersPage from "./pages/UsersPage";
import LoginPage from "./pages/LoginPage";
import ErrorPage from "./pages/ErrorPage";
import {NotPrivateRoute} from "./components/common/NotPrivateRoute";
import {PrivateRoute} from "./components/common/PrivateRoute";
import MainLayout from "./components/layouts/MainLayout";
import SimpleLayout from "./components/layouts/SimpleLayout";
import RegistrationPage from "./pages/RegistrationPage";
import ReportTimePage from "./pages/ReportPages/ReportTimePage";
import ReportOffersPage from "./pages/ReportPages/ReportOffersPage";
import ReportConversionPage from "./pages/ReportPages/ReportConversionPage";
import UserPage from "./pages/EditСreatePages/UserPage";
import CommunicationPage from "./pages/EditСreatePages/CommunicationPage";
import OffersPage from "./pages/OffersPage";
import OffersCreatePage from "./pages/OffersCreatePage";
import {AdminPrivateRoute} from "./components/common/AdminPrivateRoute";
import LeadsPage from "./pages/LeadsPage";
import LeadsCreatePage from "./pages/LeadsCreatePage";
import LinkPage from "./pages/LinkPage";
import CommunicationLeadPage from "./pages/EditСreatePages/CommunicationLeadPage";
import OffersCreatePageNew from "./pages/OffersCreatePageNew";
import ReportLeadgidConversionPage from "./pages/ReportPages/ReportLeadgidConversionPage";
import ReportLeadgidOffersPage from "./pages/ReportPages/ReportLeadgidOffersPage";
import ReportLeadgidTimePage from "./pages/ReportPages/ReportLeadgidTimePage";

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<AdminPrivateRoute/>}>
                    <Route path="/*" element={<MainLayout/>}>
                        <Route path="users" element={<UsersPage/>}/>
                        <Route path="users/:id" element={<UserPage/>}/>
                        <Route path="users/:id/create" element={<CommunicationPage/>}/>
                        <Route path="offers/create" element={<OffersCreatePage/>}/>
                        <Route path="offers/create/new" element={<OffersCreatePageNew/>}/>
                    </Route>
                </Route>
                <Route path="/*" element={<PrivateRoute/>}>
                    <Route path="/*" element={<MainLayout/>}>
                        <Route index element={<OffersPage/>}/>
                        <Route path="leads" element={<LeadsPage/>}/>
                        <Route path="leads/:id" element={<LeadsCreatePage/>}/>
                        <Route path="leads/:id/create/link" element={<CommunicationLeadPage/>}/>
                        <Route path="leads/create/new" element={<LeadsCreatePage/>}/>
                        <Route path="reports/time" element={<ReportTimePage/>}/>
                        <Route path="reports/offers" element={<ReportOffersPage/>}/>
                        <Route path="reports/conversion" element={<ReportConversionPage/>}/>
                        <Route path="offers/:id" element={<OffersCreatePage/>}/>

                        <Route path="reports/stats1/offers" element={<ReportOffersPage/>}/>
                        <Route path="reports/stats2/offers" element={<ReportLeadgidOffersPage/>}/>

                        <Route path="reports/stats1/conversion" element={<ReportConversionPage/>}/>
                        <Route path="reports/stats2/conversion" element={<ReportLeadgidConversionPage/>}/>

                        <Route path="reports/stats1/time" element={<ReportTimePage/>}/>
                        <Route path="reports/stats2/time" element={<ReportLeadgidTimePage/>}/>

                        <Route path="reports/stats1/offers/:id" element={<ReportOffersPage/>}/>
                        <Route path="reports/stats2/offers/:id" element={<ReportLeadgidOffersPage/>}/>

                        <Route path="reports/stats1/conversion/:id" element={<ReportConversionPage/>}/>
                        <Route path="reports/stats2/conversion/:id" element={<ReportConversionPage/>}/>

                        <Route path="reports/stats1/time/:id" element={<ReportTimePage/>}/>
                        <Route path="reports/stats2/time/:id" element={<ReportLeadgidTimePage/>}/>

                        <Route path="reports/stats1/offers/lead/:leadId" element={<ReportOffersPage/>}/>
                        <Route path="reports/stats1/conversion/lead/:leadId" element={<ReportConversionPage/>}/>
                        <Route path="reports/stats1/time/lead/:leadId" element={<ReportTimePage/>}/>

                        <Route path="reports/stats2/offers/lead/:leadId" element={<ReportLeadgidOffersPage/>}/>
                        <Route path="reports/stats2/conversion/lead/:leadId" element={<ReportLeadgidConversionPage/>}/>
                        <Route path="reports/stats2/time/lead/:leadId" element={<ReportLeadgidTimePage/>}/>
                    </Route>
                </Route>
                <Route path="/*" element={<NotPrivateRoute/>}>
                    <Route path="/*" element={<SimpleLayout/>}>
                        <Route path="login" element={<LoginPage/>}/>
                        <Route path="registration" element={<RegistrationPage/>}/>
                    </Route>
                </Route>
                <Route path="/*" element={<SimpleLayout/>}>
                    <Route path="link/:hash" element={<LinkPage/>}/>
                    <Route path="*" element={<ErrorPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
