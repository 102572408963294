import React, {useEffect} from 'react';
import Loader from "../../components/UI/Loader";
import {useParams, useSearchParams} from "react-router-dom";
import {ENDPOINTS} from "../../api/endpoints";
import {toast} from "react-toast";
import {Helmet} from "react-helmet";

export default function LinkPage() {
    const {hash} = useParams()
    const [searchParams] = useSearchParams();

    function redirectParent(hash: string) {
        ENDPOINTS.LINKS.GET_LINK_PARENT(hash).then((res) => {
            const decodedLink = decodeURIComponent(res.link);
            window.location.replace(decodedLink);
        }).catch(() => {
            toast.error('Упс. Что-то пошло не так, попробуйте позже.')
        })
    }

    function redirect(hash: string) {
        ENDPOINTS.LINKS.GET_LINK(hash).then((res) => {
            const decodedLink = decodeURIComponent(res.link);
            window.location.replace(decodedLink);
        }).catch(() => {
            toast.error('Упс. Что-то пошло не так, попробуйте позже.')
        })
    }

    useEffect(() => {
        if (hash) {
            if (searchParams.get("c") && searchParams.get("v") && searchParams.get("o")) {
                ENDPOINTS.LEADS.CREATE_BY_URL({
                    created_id: parseInt(searchParams.get("c")!),
                    vk_id: parseInt(searchParams.get("v")!),
                    offer_id: parseInt(searchParams.get("o")!),
                }).then(({custom_link}: {custom_link: string}) => {
                    redirectParent(custom_link)
                })
            } else {
                redirect(hash)
            }
        }
    }, []);

    return (
        <div>
            <Helmet>
                <title>Переадресация...</title>
            </Helmet>
        </div>
    )
}
