import React from 'react';

import s from './StatusBadge.module.scss';

interface StatusBadgeProps {
    status: string;
}

export default function StatusBadge ({ status }: StatusBadgeProps) {
    let color = '#333333';
    let text = 'Неизвестно';

    switch (status) {
        case 'pending':
            color = '#FFD568';
            text = 'На проверке';
            break;
        case 'cancelled':
            color = '#FF6868';
            text = 'Отклонено';
            break;
        case 'confirmed':
            color = '#68FF77';
            text = 'Одобрено';
            break;
        case 'approved':
            color = '#68FF77';
            text = 'Одобрено';
            break;
        default:
            break;
    }

    return (
        <div className={s.statusContainer}>
            <div className={s.statusContainer__circle} style={{ backgroundColor: color }} />
            <div className={s.statusContainer__text}>{text}</div>
        </div>
    )
}
