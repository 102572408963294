import React, {Fragment, useEffect, useState} from 'react';

import s from './ReportLeadgidTimePage.module.scss'
import {ITableDataHeader} from "../../../components/UI/Table/Table";
import Table from "../../../components/UI/Table";
import TableRow from "../../../components/UI/Table/components/TableRow";
import TableColumn from "../../../components/UI/Table/components/TableColumn/TableColumn";
import {ENDPOINTS} from "../../../api/endpoints";
import Loader from "../../../components/UI/Loader";
import {useParams} from "react-router-dom";
import {DateTime} from "luxon";


export default function ReportLeadgidTimePage() {
    const tableGrid = ['200px', '140px', '140px', '140px', '140px', '140px', '100px', '100px', '100px', '100px'];
    const headerData: ITableDataHeader[] = [
        {title: 'Дата'},
        {title: 'Клик. всего'},
        {title: 'Клик. уник.'},
        {title: 'Лид. всего'},
        {title: 'Лид. прин.'},
        {title: 'Лид. на проверке'},
        {title: 'Лид. отклонено'},
        {title: 'CR, %'},
        {title: 'AR, %'},
        {title: 'EPC'},
    ];

    const DATA_PAGE_LIMIT: number = 20
    const [dateFrom, setDateFrom] = useState<string>(DateTime.now().toISODate())
    const [dateTo, setDateTo] = useState<string>(DateTime.now().toISODate())
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [rawData, setRawData] = React.useState<any>({
        data: [],
        pagination: {}
    });
    const {id, leadId} = useParams()

    function getData(date_from: string, date_to: string) {
        setRawData({data: [], pagination: {}})
        setIsLoading(true)

        ENDPOINTS.REPORTS.STATS2.TIME.GET({
            id: !leadId ? id : undefined,
            lead_id: leadId,
            limit: DATA_PAGE_LIMIT,
            date_to: date_to,
            date_from: date_from,
            slice: ['year', 'month', 'day'],
        }).then((res) => {
            setRawData(res);
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getData(dateFrom, dateTo)
    }, [dateFrom, dateTo]);


    return (
        <div className={s.container}>
            <div className={s.container__products}>

                <div className={s.container__filter}>
                    <h3>Фильтровать: </h3>
                    <div>
                        <label htmlFor="date_from">
                            <span>От</span>
                            <input id="date_from" type="date" defaultValue={DateTime.now().toISODate()} onChange={(e) => setDateFrom(e.target.value)}/>
                        </label>

                        <label htmlFor="date_to">
                            <span>До</span>
                            <input id="date_to" type="date" defaultValue={DateTime.now().toISODate()} onChange={(e) => setDateTo(e.target.value)}/>
                        </label>
                    </div>
                </div>

                <Table dataHeaders={headerData} gridWidthSize={tableGrid}>
                    {headerData && <div className={s.underlined}/>}
                    {rawData?.data?.report ? rawData?.data?.report.map((item: any, index: number) => (
                        <Fragment key={index}>
                            <TableRow gridWidthSize={tableGrid}>
                                <TableColumn>
                                    <div>{DateTime.fromFormat(item.dm_lead_date, 'yyyy-LL-dd').toFormat('dd.LL.yyyy')}</div>
                                </TableColumn>
                                <TableColumn>
                                    <div>{item?.mt_clicks_total}</div>
                                </TableColumn>
                                <TableColumn>
                                    <div>{item?.mt_clicks_unique}</div>
                                </TableColumn>
                                <TableColumn>
                                    <div>{item?.mt_leads_total}</div>
                                </TableColumn>
                                <TableColumn>
                                    <div>{item?.mt_leads_approved}</div>
                                </TableColumn>
                                <TableColumn>
                                    <div>{item?.mt_leads_pending}</div>
                                </TableColumn>
                                <TableColumn>
                                    <div>{item?.mt_leads_rejected}</div>
                                </TableColumn>
                                <TableColumn>
                                    <div>{item?.mt_cr_u}</div>
                                </TableColumn>
                                <TableColumn>
                                    <div>{item?.mt_ar}</div>
                                </TableColumn>
                                <TableColumn>
                                    <div>{item?.mt_epc_u}</div>
                                </TableColumn>
                            </TableRow>
                        </Fragment>
                    )): ""}
                </Table>

                {isLoading && <Loader/>}
            </div>
        </div>
    )
}
