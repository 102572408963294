import axios, {AxiosResponse} from "axios"
import Cookies from "js-cookie"
import {LoginForm, RegistrationForm, UserInfo} from "./interface";
import {DataXPartnersOffers, DataXPartnersStats} from "./interface/Unicom";
import {Offers} from "./interface/Offers";
import {Links} from "./interface/Links";
import {Leads} from "./interface/Leads";
import {ReportsQueryInterface} from "./interface/ReportsQueryInterface";

// const BASE_URL = "http://localhost"
const BASE_URL = "https://api.hesoyam-leads.ru"

const getToken = () => Cookies.get('token')

export const ENDPOINTS_URL = {
    USERS: BASE_URL + '/users',
    PROFILE: BASE_URL + '/profile',
    AUTH: BASE_URL + '/auth',
    UNICOM: BASE_URL + '/unicom',
    OFFERS: BASE_URL + '/offers',
    LINKS: BASE_URL + '/links',
    LEADS: BASE_URL + '/leads',
    REPORTS: BASE_URL + '/reports',
}

export const ENDPOINTS = {
    PROFILE: {
        GET_iNFO: async () => {
            return await axios({
                url: ENDPOINTS_URL.PROFILE,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }
            })
                .then((res: AxiosResponse<UserInfo>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
    },
    AUTH: {
        REGISTER: (data: RegistrationForm) => {
            return axios({
                url: ENDPOINTS_URL.AUTH + '/register',
                method: 'POST',
                data: data
            })
        },
        LOGIN: (data: LoginForm) => {
            return axios({
                url: ENDPOINTS_URL.AUTH + '/login',
                method: 'POST',
                data: data
            })
        },
        LOGIN_ADMIN: (data: {id: number}) => {
            return axios<{access_token: string}, any>({
                url: ENDPOINTS_URL.AUTH + '/login/admin',
                method: 'POST',
                data: data,
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json",
                }
            })
        },
    },
    UNICOM_OFFERS: {
        GET: async (page: number, limit: number, search?: string) => {
            const query = new URLSearchParams()
            query.set('page', page.toString())
            query.set('limit', limit.toString())

            if (search)
                query.set('search', search)

            console.log(query.toString())

            return await axios({
                url: ENDPOINTS_URL.UNICOM + `/offers?${query.toString()}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }
            })
                .then((res: AxiosResponse<DataXPartnersOffers>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
    },
    OFFERS: {
        GET: async () => {
            return await axios({
                url: ENDPOINTS_URL.OFFERS,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }
            })
                .then((res: AxiosResponse<Offers[]>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        GET_MY: async () => {
            return await axios({
                url: ENDPOINTS_URL.OFFERS + "/my/list",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }
            })
                .then((res: AxiosResponse<Offers[]>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        GET_ALL: async () => {
            return await axios({
                url: ENDPOINTS_URL.OFFERS + "/all/list",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }
            })
                .then((res: AxiosResponse<Offers[]>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        CREATE_OR_REMOVE: async (unicom_id: number) => {
            return await axios({
                url: ENDPOINTS_URL.OFFERS,
                method: 'POST',
                data: {
                    unicom_id: unicom_id,
                },
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json",
                }
            })
                .then((res: AxiosResponse<Offers>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        CREATE_NEW: async ({title, leadgid_id, image}: {title?: FormDataEntryValue | string, leadgid_id?: FormDataEntryValue | number, image?: FormDataEntryValue | string}) => {
            return await axios({
                url: ENDPOINTS_URL.OFFERS + "/new",
                method: 'POST',
                data: {
                    title,
                    leadgid_id,
                    image
                },
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json",
                }
            })
                .then((res: AxiosResponse<Offers>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        DELETE_NEW: async (id: number) => {
            return await axios({
                url: ENDPOINTS_URL.OFFERS  + '/new',
                method: 'DELETE',
                data: {
                    id: id
                },
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json",
                }
            })
                .then((res: AxiosResponse<Offers>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
    },
    USERS: {
        GET: async () => {
            return await axios({
                url: ENDPOINTS_URL.USERS,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }
            })
                .then((res: AxiosResponse<UserInfo[]>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        GET_BY_ID: async (id: number) => {
            return await axios({
                url: ENDPOINTS_URL.USERS + '/' + id,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }
            })
                .then((res: AxiosResponse<UserInfo>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        UPDATE: async (data: {
            id: number,
            email?: FormDataEntryValue | string | undefined,
            balance?: FormDataEntryValue | number | undefined
            leadgid_token?: FormDataEntryValue | string | undefined
        }) => {
            return await axios({
                url: ENDPOINTS_URL.USERS + "/" + data.id,
                method: 'PUT',
                data: data,
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json",
                }
            })
                .then((res: AxiosResponse<Links[]>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        REMOVE: async (id: number) => {
            return await axios({
                url: ENDPOINTS_URL.USERS + "/" + id,
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json",
                }
            })
                .then((res: AxiosResponse<Links[]>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        }
    },
    LINKS: {
        GET: async () => {
            return await axios({
                url: ENDPOINTS_URL.LINKS,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }
            })
                .then((res: AxiosResponse<Links[]>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        GET_LINK_PARENT: async (hash: string) => {
            return await axios({
                url: ENDPOINTS_URL.LINKS + "/get/link/parent/" + hash,
                method: 'GET'
            })
                .then((res: AxiosResponse<{ link: string }>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        GET_LINK: async (hash: string) => {
            return await axios({
                url: ENDPOINTS_URL.LINKS + "/get/link/" + hash,
                method: 'GET'
            })
                .then((res: AxiosResponse<{ link: string }>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        GET_BY_ID_USER: async (id: number) => {
            return await axios({
                url: ENDPOINTS_URL.LINKS + "/" + id,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }
            })
                .then((res: AxiosResponse<Links[]>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        GET_BY_ID_USER_LEAD: async (id: number, lead: string) => {
            return await axios({
                url: `${ENDPOINTS_URL.LINKS}/${id}/lead/${lead}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }
            })
                .then((res: AxiosResponse<Links[]>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        GET_BY_ID_LEAD: async (id: string) => {
            return await axios({
                url: ENDPOINTS_URL.LINKS + "/" + id + "/lead",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }
            })
                .then((res: AxiosResponse<Links[]>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        CREATE_OR_UPDATE: async (data: {
            original_link: string | FormDataEntryValue | null,
            price?: number | FormDataEntryValue | null,
            offer_id?: number | FormDataEntryValue | null,
            user_id?: number | FormDataEntryValue | null,
            lead_id?: number | FormDataEntryValue | null
        }) => {
            return await axios({
                url: ENDPOINTS_URL.LINKS,
                method: 'POST',
                data: data,
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json",
                }
            })
                .then((res: AxiosResponse<Links>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        REMOVE: async (id: number) => {
            return await axios({
                url: ENDPOINTS_URL.LINKS + "/" + id,
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json",
                }
            })
                .then((res: AxiosResponse<Links[]>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        }
    },
    LEADS: {
        GET: async () => {
            return await axios({
                url: ENDPOINTS_URL.LEADS,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }
            })
                .then((res: AxiosResponse<Leads[]>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        GET_BY_ID: async (id: string) => {
            return await axios({
                url: ENDPOINTS_URL.LEADS + "/" + id,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }
            })
                .then((res: AxiosResponse<Leads>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        CREATE: async ({username}: { username?: string | FormDataEntryValue | null }) => {
            return await axios({
                url: ENDPOINTS_URL.LEADS,
                method: 'POST',
                data: {
                    username: username
                },
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json"
                }
            })
                .then((res: AxiosResponse<Leads>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        //http://localhost:3000/leads/link?created_id=1&username=Bot&vk_id=0
        CREATE_BY_URL: async ({created_id, vk_id, offer_id}: { created_id: number, vk_id: number, offer_id: number}): Promise<{custom_link: string}> => {
            return await axios({
                url: `${ENDPOINTS_URL.LEADS}/link`,
                method: 'POST',
                data: {
                    created_id: created_id,
                    vk_id: vk_id,
                    offer_id: offer_id
                },
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json"
                }
            })
                .then((res: AxiosResponse<{custom_link: string}>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        UPDATE: async ({id, username}: { id: number, username?: string | FormDataEntryValue | null }) => {
            return await axios({
                url: ENDPOINTS_URL.LEADS + "/" + id,
                method: 'PUT',
                data: {
                    username: username
                },
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json"
                }
            })
                .then((res: AxiosResponse<Leads>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
        DELETE: async (id: string) => {
            return await axios({
                url: ENDPOINTS_URL.LEADS + "/" + id,
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }
            })
                .then((res: AxiosResponse<Leads>) => {
                    return res.data
                })
                .catch((err: any) => {
                    throw err
                })
        },
    },
    REPORTS: {
        STATS1: {
            CONVERSATION: {
                GET: async ({id, lead_id, page, limit, date_from, date_to}: ReportsQueryInterface) => {
                    const query = new URLSearchParams()

                    if (id) query.set('id', id.toString())
                    if (lead_id) query.set('lead_id', lead_id.toString())
                    if (page) query.set('page', page.toString())
                    if (limit) query.set('limit', limit.toString())
                    if (date_from) query.set('date_from', date_from.toString())
                    if (date_to) query.set('date_to', date_to.toString())

                    return await axios({
                        url: `${ENDPOINTS_URL.REPORTS}/stats1/conversation?${query.toString()}`,
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                        }
                    })
                        .then((res: AxiosResponse<DataXPartnersStats>) => {
                            return res.data
                        })
                        .catch((err: any) => {
                            throw err
                        })
                },
            },
            CUSTOM: {
                GET: async ({id, lead_id, page, limit, date_from, date_to, slice}: ReportsQueryInterface) => {
                    const query = new URLSearchParams()

                    if (id) query.set('id', id.toString())
                    if (lead_id) query.set('lead_id', lead_id.toString())
                    if (page) query.set('page', page.toString())
                    if (limit) query.set('limit', limit.toString())
                    if (date_from) query.set('date_from', date_from.toString())
                    if (date_to) query.set('date_to', date_to.toString())

                    if(slice) {
                        for (let i = 0; i < slice.length; i++) {
                            query.append('slice[]', slice[i].toString())
                        }
                    }

                    return await axios({
                        url: `${ENDPOINTS_URL.REPORTS}/stats1/custom?${query.toString()}`,
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                        }
                    })
                        .then((res: AxiosResponse<DataXPartnersStats>) => {
                            return res.data
                        })
                        .catch((err: any) => {
                            throw err
                        })
                },
            },
        },
        STATS2: {
            CONVERSATION: {
                GET: async ({id, lead_id, page, limit, date_from, date_to}: ReportsQueryInterface) => {
                    const query = new URLSearchParams()

                    if (id) query.set('id', id.toString())
                    if (lead_id) query.set('lead_id', lead_id.toString())
                    if (page) query.set('page', page.toString())
                    if (limit) query.set('limit', limit.toString())
                    if (date_from) query.set('date_from', date_from.toString())
                    if (date_to) query.set('date_to', date_to.toString())

                    return await axios({
                        url: `${ENDPOINTS_URL.REPORTS}/stats2/conversation?${query.toString()}`,
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                        }
                    })
                        .then((res: AxiosResponse<DataXPartnersStats>) => {
                            return res.data
                        })
                        .catch((err: any) => {
                            throw err
                        })
                },
            },
            TIME: {
                GET: async ({id, lead_id, page, limit, date_from, date_to}: ReportsQueryInterface) => {
                    const query = new URLSearchParams()

                    if (id) query.set('id', id.toString())
                    if (lead_id) query.set('lead_id', lead_id.toString())
                    if (page) query.set('page', page.toString())
                    if (limit) query.set('limit', limit.toString())
                    if (date_from) query.set('date_from', date_from.toString())
                    if (date_to) query.set('date_to', date_to.toString())

                    return await axios({
                        url: `${ENDPOINTS_URL.REPORTS}/stats2/time?${query.toString()}`,
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                        }
                    })
                        .then((res: AxiosResponse<any>) => {
                            return res.data
                        })
                        .catch((err: any) => {
                            throw err
                        })
                },
            },
            OFFERS: {
                GET: async ({id, lead_id, page, limit, date_from, date_to}: ReportsQueryInterface) => {
                    const query = new URLSearchParams()

                    if (id) query.set('id', id.toString())
                    if (lead_id) query.set('lead_id', lead_id.toString())
                    if (page) query.set('page', page.toString())
                    if (limit) query.set('limit', limit.toString())
                    if (date_from) query.set('date_from', date_from.toString())
                    if (date_to) query.set('date_to', date_to.toString())

                    return await axios({
                        url: `${ENDPOINTS_URL.REPORTS}/stats2/offers?${query.toString()}`,
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                        }
                    })
                        .then((res: AxiosResponse<DataXPartnersStats>) => {
                            return res.data
                        })
                        .catch((err: any) => {
                            throw err
                        })
                },
            },
        }

    },
}
