import {FooterItemType} from "../types/FooterItemType";

export const footerData: FooterItemType[] = [
    {
        id: 1,
        route: "/",
        title: "Офферы",
    },
    {
        id: 2,
        route: "/leads",
        title: "Лиды",
    }, {
        id: 3,
        route: "/users",
        title: "Пользователи",
        is_admin: true
    },
    {
        id: 4,
        route: "/reports/stats1/time",
        title: "Отчёты",
    },
    {
        id: 5,
        title: "Профиль",
        menu: true,
    },
]