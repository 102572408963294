import React, {useEffect, useState} from 'react';

import s from './MainLayout.module.scss'
import HeaderLayout from "../HeaderLayout";
import {Outlet} from "react-router";
import {useLocation, useParams} from "react-router-dom";
import AsideLayout from "../AsideLayout";
import classNames from "classnames";
import FooterLayout from "../FooterLayout";
import {useAppSelector} from "../../../store";
import {UserInfo} from "../../../api/interface";
import {ENDPOINTS} from "../../../api/endpoints";
import Loader from "../../UI/Loader";
import {Leads} from "../../../api/interface/Leads";

export default function MainLayout() {
    const location = useLocation()
    const [isMobile, setIsMobile] = useState<boolean>(false)

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const {rights} = useAppSelector((state) => state.profile)
    const [user, setUser] = useState<UserInfo>()
    const [lead, setLead] = useState<Leads>()
    const {id, leadId} = useParams()


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 900);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (location.pathname.includes('reports') && id && rights === "ADMIN") {
            ENDPOINTS.USERS.GET_BY_ID(parseInt(id)).then((res) => {
                setUser(res)
                setIsLoading(false)
            })
        }
    }, [id]);

    useEffect(() => {
        if (location.pathname.includes('reports') && leadId) {
            ENDPOINTS.LEADS.GET_BY_ID(leadId).then((res) => {
                setLead(res)
                setIsLoading(false)
            })
        }
    }, [leadId]);


    return (
        <div className={s.main}>
            {!isMobile && <HeaderLayout/>}
            <div className={classNames({
                [s.main__content]: true,
            })}>
                <div className={classNames({
                    [s.main__content__grid]: location.pathname.includes('reports'),
                })}>
                    {location.pathname.includes('reports') &&
                        <div className={s.main__content__grid__leftPart}>
                            {(id || leadId) && <>
                                <div className={s.main__content__user}>
                                    {isLoading && <Loader/>}

                                    {id && user && <>
                                        <h3>Пользователь</h3>
                                        <p>{user.email}</p>
                                    </>}

                                    {leadId && lead && <>
                                        <h3>Лид</h3>
                                        <p>{lead.username}</p>
                                    </>}
                                </div>

                            </>}
                            <AsideLayout user={user} lead={lead}/>
                        </div>
                    }
                    <div className={s.main__content__grid__rightPart}>
                        <Outlet/>
                    </div>
                </div>
            </div>
            {isMobile && <div className={s.main__footer}>
                <FooterLayout/>
            </div>}
        </div>
    )
}
