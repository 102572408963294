import React, {useState} from 'react';

import avatar from '../../../../../assets/images/cat-6463284_640.jpg'

import s from './UserProfile.module.scss'
import ArrowDown from "../../../../../assets/icons/ArrowDown";
import classNames from "classnames";
import {useDispatch} from "react-redux";
import {setIsAuthenticated} from "../../../../../store/reducers/authReducer";
import Cookies from "js-cookie";
import {useAppSelector} from "../../../../../store";

export default function UserProfile() {
    const [isOpen, setIsOpen] = useState(false);
    const {email, balance} = useAppSelector((state) => state.profile)

    const dispatch = useDispatch()

    const toggleProfile = () => {
        setIsOpen(!isOpen);
    }

    const logout = () => {
        dispatch(setIsAuthenticated(false));
        Cookies.remove('token')
        window.location.href = '/';
    }

    return (
        <button className={classNames({
            [s.userProfile]: true,
            [s.userProfile__active]: isOpen,
        })} onClick={toggleProfile}>
            <div className={s.userProfile__content}>
                <div className={s.userProfile__content__userName}>
                    {email}
                </div>
                <div className={classNames({
                    [s.userProfile__content__arrow]: true,
                    [s.userProfile__content__arrow__active]: isOpen,
                })}>
                    <ArrowDown/>
                </div>
            </div>
            <div className={classNames({
                [s.userProfile__options ]: true,
                [s.userProfile__options__active]: isOpen,
            })}>
                <div>Баланс: {balance} ₽</div>
                <button onClick={logout}>Выйти</button>
            </div>
        </button>
    )
}
